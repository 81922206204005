import React from "react"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "components/layout"
import SEO from "components/seo"

import Img from "gatsby-image"

// import { Cover } from "components/fostive/cover/cover"

import { Container, Section } from "components/anti/grid/grid"
// import
// import { CardSlider } from "components/fostive/card-slider/card-slider"

import { FooterBrand } from "components/fostive/footer-brand/footer-brand"

import icBack from "assets/img/common/ic_back.png"

import { replaceUnicode } from "src/utils/mixins"
import { getYoutubeId } from "../utils/mixins"

// import imgDummy1 from "assets/img/sample/img_dummy_1.jpg"

const videoOptions = {
  playerVars: {
    autoplay: 0,
    controls: 1,
    rel: 0,
    showinfo: 0,
    mute: 0,
    color: "white",
    fs: 1,
    modestbranding: 1,
  },
}

const WorkDetails = ({ location, pageContext }) => {
  const headlineBlock = pageContext.data.blocks.find(
    block => block.name === "acf/headline"
  )

  const galleryBlock = pageContext.data.blocks.find(
    gallery => gallery.name === "acf/gallery"
  )

  const youtubeUrl = pageContext?.data?.blocks?.filter(
    block => block.name === "core-embed/youtube"
  )

  const headline = headlineBlock && headlineBlock.headline

  const gallery =
    galleryBlock &&
    galleryBlock.gallery &&
    galleryBlock.gallery &&
    galleryBlock.gallery.gallery

  return (
    <Layout footer={false} background="#9E9E9E">
      <SEO title={replaceUnicode(pageContext.data.title)} />

      <div>
        <Section className="text-white d-none d-sm-block">
          <Container className="mw-xl">
            <div className="d-flex mb-4">
              <Link
                to={`${location.pathname.slice(
                  0,
                  location.pathname.lastIndexOf("/")
                )}`}
                className="animated fadeInUp delayp1"
              >
                <img src={icBack} alt="back" width="32px" />
              </Link>
            </div>
            <span
              style={{ fontSize: "20px" }}
              className="d-block mb-2 animated fadeInUp delayp2"
            >
              {headline?.label}
            </span>
            <h2 className="mb-3 custom-title-works animated fadeInUp delayp3">
              {replaceUnicode(pageContext.data.title)}
            </h2>
            <div className="mw-md mb-5 animated fadeInUp delayp4">
              <p className="text-white-50">
                {replaceUnicode(headline && headline && headline.text)}
              </p>
            </div>
          </Container>

          <Container>
            <div className="mw-100 animated fadeInUp delayp5">
              {youtubeUrl?.map((item, i) => (
                <div
                  className="embed-responsive embed-responsive-16by9 mb-3"
                  key={i}
                >
                  <YouTube
                    opts={videoOptions}
                    className="w-100 embed-responsive-item"
                    videoId={getYoutubeId(item?.attributes?.url)}
                  />
                </div>
              ))}
              {gallery &&
                gallery.map((item, i) => (
                  <Img
                    key={i}
                    fluid={item && item.sourceUrlSharp.childImageSharp.fluid}
                    alt="gallery"
                    width="100%"
                    className="mb-3"
                  />
                ))}
            </div>
          </Container>

          <Container className="mw-xl d-none d-sm-block">
            <div className="mw-100">
              {/* <CardSlider
                showInitial={1}
                showLgDown={1}
                showMdDown={1}
                arrowsInitial={false}
                arrowsLgDown={false}
                infinite={true}
                scrollInitial={1}
                className="center"
              >
                {pageContext.data.blocks
                  .find(block => block.name === "acf/gallery")
                  .gallery.gallery.map((item, i) => (
                    <img src={item.sourceUrl} alt="gallery" />
                  ))}
              </CardSlider> */}
            </div>
          </Container>
        </Section>
        <Section className="text-white d-block d-sm-none">
          <Container className="mw-xl">
            <div className="d-flex mb-4">
              <Link
                to={`${location.pathname.slice(
                  0,
                  location.pathname.lastIndexOf("/")
                )}`}
              >
                <img src={icBack} alt="back" width="32px" />
              </Link>
            </div>
            <span style={{ fontSize: "20px" }} className="d-block mb-2">
              {headline && headline.label}
            </span>
            <h2 className="mb-3 custom-title-works">
              {replaceUnicode(pageContext.data.title)}
            </h2>
            <div className="mw-md mb-5">
              <p className="text-white-50">
                {replaceUnicode(headline && headline.text)}
              </p>
            </div>
          </Container>

          <div className="mw-100 d-block d-sm-none">
            {youtubeUrl?.map((item, i) => (
              <div
                className="embed-responsive embed-responsive-16by9 mb-3"
                key={i}
              >
                <YouTube
                  opts={videoOptions}
                  className="w-100 embed-responsive-item"
                  videoId={getYoutubeId(item?.attributes?.url)}
                />
              </div>
            ))}
            {gallery &&
              gallery.map((item, i) => (
                <Img
                  key={i}
                  fluid={item && item.sourceUrlSharp.childImageSharp.fluid}
                  alt="gallery"
                  width="100%"
                  className="mb-3"
                />
              ))}
          </div>
          <Container className="mw-xl mt-4">
            <FooterBrand mobile={true} />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default WorkDetails
